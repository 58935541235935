.notification-container {
  top: 30% !important;
}

.delete-nft {
  background-image: url(../../src/Assets/images/white-cross.svg);
  background-position: center center;
  background-repeat: no-repeat;
}

.notification {
  opacity: 1 !important;
}

.edit-cover-new {
  z-index: 100;
  position: absolute;
  right: 100px;
  bottom: 10px;
}

body.dark > * {
  background-color: rgb(32, 34, 37) !important;
  border-color: black !important;
}

body.dark .item {
  border-color: white !important;
  background-color: #eef2f7 !important;
  border-radius: 10px 10px 0 0;
}

body.dark .item-dark {
  background-color: rgb(32, 34, 37) !important;
  border-radius: 10px 10px 0 0;
}

body.dark .item-dark .by {
  color: white;
}

body.dark .item-dark .NFT-home-box {
  border-color: white !important;
}

body.dark .item-dark .NFT-home-box .NFT-home-box-inner h4 {
  color: white !important;
}

/* body.dark .item-dark .NFT-home-box .NFT-home-box-inner p{
  color: white !important;
} */

body.dark .item-dark .NFT-home-box .NFT-home-box-inner .username {
  color: white !important;
}

body.dark .item-dark .NFT-home-box .NFT-home-box-inner .JCSB {
  background-color: rgb(32, 34, 37) !important;
}

body.dark .item-dark .NFT-home-box .NFT-home-box-inner .ed-box p {
  color: white !important;
}

body.dark .item-dark .NFT-home-box .NFT-home-box-inner .ed-box h3 {
  color: white !important;
}

body.dark .item-dark .ed-box p {
  color: white !important;
}

body.dark .item-dark .ed-box h3 {
  color: white !important;
}

body.dark .item.inner .ed-box .ed-left .ed-left-inner p {
  color: white !important;
}

body.dark .item.inner .ed-box .ed-left .ed-left-inner h3 {
  color: white !important;
}

body.dark .item-dark a div {
  color: white !important;
}

body.dark .item-dark a {
  color: white !important;
}

body.dark .item-dark a .item {
  background-color: transparent !important;
  color: white !important;
}

body.dark .item-dark a .item span {
  color: white !important;
}

body.dark .item > * {
  color: black !important;
}

body.dark .item.inner {
  background-color: rgb(32, 34, 37) !important;
  margin-left: 25px;
  color: white !important;
}

body.dark .item.inner div {
  color: white !important;
}

body.dark .item.inner .username {
  color: white !important;
}

body.dark .item.inner .view {
  color: white !important;
  /* overflow: auto !important; */
}

.item.inner .nft-details-box {
  flex-wrap: nowrap;
}

body.dark .nav {
  background-color: rgb(32, 34, 37) !important;
  color: #fff !important;
  border-bottom: 1px solid black;
}

body.dark .nav.gradient-header {
  background-color: transparent !important;
  border-bottom: none !important;
}

body.dark .nav > * {
  color: #fff !important;
}

body.dark .nav .mobile-logo .avangart-Logo {
  background: url(../../src/Assets/images/carny_white_colored_1.svg) no-repeat !important;
}

body.dark .footer-logo {
  background: url(../../src/Assets/images/carny_white_colored_1.svg) no-repeat !important;
}

body.dark .nav .desktop-menu a {
  color: white !important;
}

body.dark .nav .desktop-menu .Lang-text {
  color: white !important;
}

body.dark .nav .desktop-menu a.active::after {
  background-color: white !important;
}

body.dark .nav .desktop-menu a:hover:after {
  background-color: #fff;
}

body.dark .home-title,
.star-title,
.heart-title {
  border-color: white !important;
}

body.dark .home-title h3 {
  color: white !important;
}

body.dark .star-title h3 {
  color: white !important;
}

body.dark .heart-title h3 {
  color: white !important;
}

body.dark .no-found-data {
  color: white !important;
}

body.dark .react-tabs__tab-list {
  border-color: white !important;
}

body.dark .react-tabs__tab-list .react-tabs__tab {
  color: white !important;
  background-color: transparent !important;
}

body.dark .react-tabs__tab-list .react-tabs__tab--selected {
  border-color: white !important;
}

body.dark .nav .active.noti-button-outer {
  background-color: black;
  border-color: black;
  color: #fff;
}

.RedDot.active {
  background-color: #ff2a44 !important;
}

body.dark .nav .noti-button-outer {
  border: 1px solid white !important;
}

body.dark .gradient-header .noti-button-outer {
  border: 1px solid white !important;
}

body.dark .noti-button-outer:hover {
  background-color: transparent !important;
}

body.dark .noti-button-outer .Notifi-Icon {
  background-image: url(../../src/Assets/images/notification-white.svg);
}

.gradient-header .noti-button-outer .Notifi-Icon {
  background-image: url(../../src/Assets/images/notification-white.svg) !important;
}

body.dark .wabtn {
  border-color: white !important;
  color: white !important;
}

body.dark .nav .ph-bg {
  background-color: black;
}

body.dark .nav .ph-bg span {
  color: #fff;
}

body.dark .lazyload-wrapper button {
  color: white !important;
  border-color: white !important;
}

body.dark .lazyload-wrapper button:hover {
  background-color: #d121d6 !important;
  border-color: transparent !important;
}

body.dark .no-data {
  color: white !important;
}

body.dark .load-more-btn {
  color: white !important;
  border: 1px solid white !important;
  background-color: transparent !important;
}

body.dark .load-more-btn:hover {
  background-color: #d121d6 !important;
  border-color: transparent !important;
}

body.dark #all.active,
#sold.active,
#liveauction.active,
#buynow.active {
  background-color: #d121d6 !important;
}

body.dark #all:hover,
#sold:hover,
#liveauction:hover,
#buynow:hover {
  background-color: #d121d6 !important;
}

body.dark .view button.active {
  background-color: #d121d6 !important;
}

body.dark .view button:hover {
  background-color: #d121d6 !important;
}

body.dark .view button[type="submit"] {
  color: black !important;
  border-color: black !important;
}

body.dark .view button[type="submit"]:hover {
  color: white !important;
  border-color: transparent !important;
}

body.dark .react-tabs__tab-panel .Bec {
  color: white !important;
}

body.dark .react-tabs__tab-panel .ani-1 {
  color: white !important;
  border-color: white !important;
}

body.dark .react-tabs__tab-panel .ani-1:hover {
  background-color: #d121d6 !important;
  border-color: #d121d6 !important;
}

body.dark #terms > * {
  color: white !important;
}

body.dark #privacy > * {
  color: white !important;
}

body.dark #cookie > * {
  color: white !important;
}

body.dark .text-white {
  color: white !important;
}

body.dark .text-black {
  color: black !important;
}

body.dark .bg-transparent {
  background-color: transparent !important;
}

body.dark .text-white.active {
  border-color: white !important;
}

body.dark .dark-theme-btn {
  background-color: transparent !important;
  color: white !important;
  border-color: white !important;
  border: 1px solid;
}

body.dark .all-field-required {
  border: 1px solid white;
}

body.dark .dark-theme-btn:hover {
  background-color: #d121d6 !important;
  border-color: #d121d6 !important;
}

body.dark .Collapsible__trigger {
  color: white !important;
}

body.dark .Collapsible__contentInner p {
  color: white !important;
}

body.dark .Collapsible__trigger.is-open + .Collapsible__contentOuter {
  border-color: #d121d6 !important;
}

body.dark .Collapsible__trigger.is-closed::after {
  background: url(../../src/Assets/images/white-cross.svg);
  transform: rotate(45deg);
}

body.dark .Collapsible__trigger.is-open::after {
  background: url(../../src/Assets/images/purple-cross.svg);
}

body.dark .inactive {
  color: white !important;
}

body.dark .inactive:hover {
  border-color: white !important;
}

body.dark .active {
  border-color: white !important;
  color: white !important;
}

body.dark .app__collapse .active {
  color: black !important;
}

.app__collapse .loader::before {
  background-color: #ffffff !important;
}

body.dark .react-multi-carousel-list button {
  color: rgb(0 0 0 / 20%) !important;
}

body.dark .react-multi-carousel-list button:hover {
  background-color: transparent !important;
}

body.dark .react-multi-carousel-list .active {
  color: black !important;
}

body.dark .react-multi-carousel-list .active {
  background-color: transparent !important;
}

body.dark select {
  background: url(../../src/Assets/images/dd-down-arrow-white.svg) no-repeat 97% 55%;
}

body.dark select option {
  color: black !important;
}

body.dark .radio-container.text-white img:first-child {
  content: url(../../src/Assets/images/icon-set-auction-white.svg);
}

body.dark .radio-container.text-white img:last-child(2) {
  content: url(../../src/Assets/images/icon-set-money-white.svg);
}

textarea {
  resize: none;
}

.body.dark .text-red {
  color: red !important;
}

#contract_type {
  background: url(../../src/Assets/images/dd-down-arrow.svg) no-repeat 97% 55%;
}

body.dark #contract_type {
  background: url(../../src/Assets/images/dd-down-arrow-white.svg) no-repeat 97% 55%;
}

.reflink-web {
  display: flex !important;
}

.reflink-mobile {
  display: none !important;
}

.nft-details-accordion-head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.nft-details-accordion-head .head-item {
  width: calc(100% / 5);
  font-size: 13px;
}

.nft-details-accordion-body {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.body-item {
  width: calc(100% / 5);
  text-align: start;
  font-size: 13px;
}

.body-item:last-child {
  text-align: end;
}

.nft-details-accordion-body button {
  border: 1px solid #000;
  border-radius: 9px;
  font-size: 10px;
  padding: 6px 15px;
  color: black;
}

.nft-details-accordion-body button:hover {
  color: white;
  background-color: #000;
}

.owners-modal-trigger {
  border: 1px solid #000;
  border-radius: 9px;
  font-size: 14px;
  padding: 6px 15px;
  color: black;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.owners-modal-trigger:hover {
  color: white;
  background-color: #000;
}

.items-count {
  font-size: 14px;
  padding: 6px 15px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.owners-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
}

.owners-list .owner-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: black;
  border-bottom: 1px solid black;
  padding: 10px 0;
}

.owner-item .owner-img {
  width: 36px;
  height: 36px;
  border: 1px solid black;
  border-radius: 50%;
  background-color: red;
  margin-right: 20px;
}

.owner-item .owner-name {
  margin-right: auto;
}

.d-none {
  display: none !important;
}

.item.inner .actions {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: flex-start;
  column-gap: 20px;
}

.item.inner .items-box {
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-bottom: 10px;
}

.bg-transparent .nft-details-accordion-head {
  flex-direction: column;
  row-gap: 10px;
}

.bg-transparent .head-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  width: 100%;
}

.bg-transparent .head-item a {
  color: #367bc3;
}

.bg-transparent .head-item a:hover {
  color: #2b629b;
}

body.dark .bg-transparent .head-item {
  color: white;
}

.activity {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px 0;
}

.activity-mobile {
  display: none;
}

.activity-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 20px 0;
  border-bottom: 1px solid black;
}

body.dark .activity-head {
  border-color: white;
}

.activity-head .head-item {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  width: 10%;
}

/* .activity-head .head-item:nth-child(2){
  width: 25%;
} */

.activity-head .head-item:first-child {
  width: 35%;
}

.activity-head .head-item:last-child {
  width: 17%;
}

.activity-body {
  display: flex;
  flex-direction: column;
}

.activity-body .body-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  border-bottom: 1px solid;
  border-color: rgb(0 0 0 / 40%);
}

body.dark .activity-body .body-item {
  border-color: white;
}

.activity-body .body-item .item-content {
  width: 10%;
  text-align: start;
  display: flex;
  align-items: center;
}

.activity-body .body-item .item-content .action-name {
  width: 25%;
  display: flex;
  align-items: center;
}

.activity-body .body-item .item-content-img {
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 12px 0 30px;
}

.activity-body .body-item .item-content .item-content-img img {
  max-width: 100%;
}

/* .activity-body .body-item .item-content:nth-child(2){
  width: 25%;
} */

.activity-body .body-item .item-content:first-child {
  width: 35%;
}

.activity-body .body-item .item-content:last-child {
  width: 17%;
}

.activity-body .body-item .item-content a {
  color: #367bc3;
}

body.dark .activity {
  color: white !important;
}

/* Easy Transfer Styles START*/

.easy-transfer-body {
  height: 100%;
  display: inline-flex;
  flex-wrap: wrap;
}

.easy-transfer-body .body-item {
  display: grid;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 155px;
  min-width: 155px;
  min-height: 160px;
  max-height: 160px;
  padding: 12px 12px;
  font-size: 16px;
  border-color: rgb(0 0 0 / 40%);
}

body.dark .easy-transfer-body .body-item {
  border-color: white;
}

.easy-transfer-body .body-item .item-content {
  display: grid;
  text-align: center;
  justify-content: center;
}

.easy-transfer-body .body-item .item-content-img {
  width: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 4px 12px 12 px 12px;
}

.easy-transfer-body .body-item .item-content .item-content-img img {
  max-width: 100%;
}

.easy-transfer-body .body-item .item-content a {
  color: #367bc3;
}

.easy-transfer-body .NFT-home-box {
  border: solid 1px;
  border-color: rgb(32, 34, 37) !important;
  border-radius: 10px;
  min-height: 200px;
  max-height: 200px;
  box-shadow: 0px 0px 10px -5px black;
}

.easy-transfer-body .NFT-home-box .NFT-home-box-inner {
  padding: 5px 5px;
  max-height: 70px;
  overflow: auto;
}

.easy-transfer-body .NFT-home-box .NFT-home-box-inner .text-black {
  margin: 0;
  padding: 0;
  color: #000 !important;
}

/* Easy Transfer Styles END*/

.categories {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
}

.category-box {
  white-space: nowrap;
  display: inline-block;
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  border-radius: 15px;
  background-color: #eef2f7;
  margin: 0px 6px 0px 0px;
  color: #000000;
}

.category-box:hover {
  background-color: #00babc;
  color: #fff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
}

.category-box:hover a {
  color: white;
}

body.dark .category-box:hover {
  background-color: #d121d6 !important;
  border-color: #d121d6 !important;
}

.category-box a {
  color: black;
  width: 100%;
  text-align: center;
}

body.dark .category-box {
  border-color: white;
}
body.dark .category-box a {
  /* color: white; */
  color: #000000;
}
.loader {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 10rem;
  background: linear-gradient(#d121d6 0%, #febf11 100%);
  box-shadow: 0px 0px 100px -50px black;
  animation: animate 1s linear infinite;
}

.loader::before {
  position: absolute;
  content: "";
  background: #fff;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  border-radius: 10rem;
}

body.dark .loader::before {
  background: rgb(32, 34, 37);
}

.special-buyer-field {
  position: relative;
}

.add-buyer-btn {
  font-size: 14px;
  color: #fff;
  -webkit-letter-spacing: -0.5px;
  -moz-letter-spacing: -0.5px;
  -ms-letter-spacing: -0.5px;
  letter-spacing: -0.5px;
  padding: 13px 60px;
  cursor: pointer;
  border-radius: 15px;
  background-color: black;
  margin-left: auto;
  margin-right: auto;
}

.hidden-input {
  position: absolute;
  z-index: -100;
  width: 1px;
  height: 1px;
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  padding: 0;
  margin: 0;
}

/* input[name='walletAddress']{
  width: 85%;
} */

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* RESPONSIVE STYLES */

@media (max-width: 767.98px) {
  .ver2 {
    right: -50% !important;
    z-index: 9999 !important;
  }

  .mobile-column {
    flex-direction: column !important;
    align-items: flex-start !important;
    row-gap: 15px !important;
  }

  .mobile-column button {
    font-size: 15px !important;
  }

  .edit-cover-new {
    bottom: 10px !important;
    right: 10px !important;
  }

  body.dark .menu-deactive {
    background: url(../..//src/Assets/images/icon-set-menu-white.svg) !important;
  }

  body.dark .menu-active {
    background: url(../../src/Assets/images/icon-set-close-white.svg) !important;
  }

  body.dark .item.inner {
    margin-left: 0 !important;
  }

  .nft-details-box {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: nowrap;
  }

  .nft-details-box--name {
    margin: 10px 0 10px 0 !important;
    padding-right: 10px;
  }

  .disconnect {
    color: black;
    border: 1px solid black;
    border-radius: 30px;
    width: 50%;
    margin: auto !important;
    margin-top: 15px !important;
    padding: 10px;
  }

  .reflink-mobile {
    padding: 0 !important;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 20px !important;
    justify-content: center !important;
  }

  .reflink-web {
    display: none !important;
  }

  .mobile-block {
    margin-bottom: 20px !important;
  }

  .edit-profile {
    margin-top: 10px !important;
  }

  .collapse-active {
    overflow: visible !important;
  }

  .item.inner .actions {
    column-gap: 20px;
  }

  .item.inner .actions .dark-theme-btn {
    margin: 0 !important;
    padding: 12px 24px !important;
  }

  .items-count {
    padding: 4px;
  }

  .JCFS .time-block {
    margin-right: 10px;
    width: auto !important;
  }

  .item.inner .items-box {
    justify-content: space-between;
  }

  .nft-details-accordion-body .body-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .activity {
    display: none;
  }

  .activity-mobile {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .activity-mobile .card {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 32px;
    overflow: hidden;
    padding: 40px 24px;
    color: black;
  }

  .activity-mobile .card a {
    color: #367bc3;
  }

  .activity-mobile .card .action-name {
    font-size: 18px;
    color: black;
    margin-bottom: 20px;
  }

  .activity-mobile .card .item-content {
    margin-bottom: 20px;
    color: black;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .activity-mobile .card .item-content .title {
    text-transform: uppercase;
    font-size: 12px;
  }

  .activity-mobile .card .item-content-img {
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    margin-bottom: 12px;
  }

  .view{
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden ;
  }
  /* input[name='walletAddress']{
    width: 80%;
  } */
}
